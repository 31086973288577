<template>
	<div>
		<el-form :model="queryForm" :inline="true" size="small">
			<el-form-item label="统计类型">
				<el-select v-model="queryForm.is_birthday" clearable placeholder="请选择">
					<el-option label="本月生日查询" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="入住类型">
				<el-select v-model="queryForm.occupancy_status" clearable placeholder="请选择">
					<el-option v-for="item in liveStatusDict" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button @click="getRecordData" type="primary">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="userData" v-loading="userLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}" border stripe
			size="small" style="width: 100%;" max-height="500px">
			<el-table-column label="#" type="index" width="60">
			</el-table-column>
			<el-table-column prop="realname" label="姓名">
			</el-table-column>
			<el-table-column prop="id_no" label="身份证">
			</el-table-column>
			<el-table-column prop="mobile" label="手机">
			</el-table-column>
			<el-table-column v-slot="{ row }" prop="name" label="性别">
				{{ sexDict[row.sex].label }}
			</el-table-column>
			<el-table-column prop="birthday" label="生日">
			</el-table-column>
			<el-table-column prop="age" label="年龄">
			</el-table-column>
			<el-table-column v-slot="{ row }" label="操作" fixed="right">
				<el-link @click="handleView(row)" type="primary">查看</el-link>
			</el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="userTotal"
				:current-page.sync="queryForm.page" :page-size="userPageSize" @current-change="getRecordData">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>

		<el-dialog title="查看" :visible.sync="viewDialog" width="50%">
			<el-descriptions v-if="curUser !== null" :column="2" border>
				<el-descriptions-item label="照片">
					<el-image :src="curUser.photo" style="width: 80px;">
						<div slot="error" style="color: #606266;">未上传相片</div>
					</el-image>
				</el-descriptions-item>
				<el-descriptions-item label="姓名">{{ curUser.realname }}</el-descriptions-item>
				<el-descriptions-item label="身份证">{{ curUser.id_no }}</el-descriptions-item>
				<el-descriptions-item label="手机">{{ curUser.mobile }}</el-descriptions-item>
				<el-descriptions-item label="生日">{{ curUser.birthday }}</el-descriptions-item>
				<el-descriptions-item label="性别">{{ sexDict[curUser.sex].label }}</el-descriptions-item>
				<el-descriptions-item label="状态">{{ liveStatusDict[curUser.occupancy_status].label }}
				</el-descriptions-item>
				<el-descriptions-item label="餐饮卡号">{{ curUser.repast_no }}</el-descriptions-item>
			</el-descriptions>
			<span slot="footer">
				<el-button @click="viewDialog = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getUserRecordDataAPI,
	} from '@/api/user-record/record.js'

	import {
		liveStatusDict,
		sexDict,
	} from '@/utils/dict.js'

	export default {
		name: 'UserRecordQuery',
		data() {
			return {
				// 入住状态字典
				liveStatusDict,
				// 性别字典
				sexDict,

				queryForm: {
					page: 1,
					is_birthday: 1,
					occupancy_status: ''
				},
				userData: [],
				userTotal: 0,
				userPageSize: 10,
				userLoading: false,
				curUser: null,

				viewDialog: false,
			}
		},
		created() {
			this.getRecordData()
		},
		methods: {
			handleView(user) {
				this.viewDialog = true
				this.curUser = user
			},
			// 获取档案数据
			getRecordData() {
				this.userLoading = true

				getUserRecordDataAPI(this.queryForm).then(res => {
					this.userData = res.data
					this.userTotal = res.total
					this.userPageSize = res.per_page
					this.userLoading = false
				})
			}
		}
	}
</script>

<style>
</style>